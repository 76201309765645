<template>
    <div v-sticky="{ isSticky: true }" class="h-100">
        <nav aria-label="Main navigation" class="h-100">
            <div class="pr--nav-right--container pb-3 pl-3 pr-3">
                <div v-if="dataIndex.data.sidebar !== null && dataIndex.data.sections.right !== undefined && dataIndex.data.sections.right.articles.length" class="h-100" data-sticky-container>
                    <FrontendArticleCardLeft
                        v-for="(article, key) in dataIndex.data.sections.right.articles"
                        :key="key"
                        :article="article"
                        :type="'compact'"
                    />
                </div>
            </div>
        </nav>
    </div>
</template>

<script setup lang="ts">
import FrontendArticleCardLeft from '@/components/Frontend/Article/FrontendArticleCardLeft.vue';
import { useStoreAppDataIndex } from '@/stores/app/data';
import { useStoreSystem } from '@/stores/system';

const dataIndex = useStoreAppDataIndex();
const system = useStoreSystem();
</script>
